<template>
    <div :id="id" :style="{ width: `${width}px`, height: `${height}px` }"></div>
</template>

<script>
export default {
    data() {
        return {

        }
    },
    props: {
        id: {
            type: String,
            require: true
        },
        ringData: {
            type: Array,
            default: []
        },
        unit: {
            type: String,
            default: '台'
        },
        width: {
            type: Number,
            default: 300
        },
        height: {
            type: Number,
            default: 300
        },
        chartTitle: {
            type: String,
            default: ''
        }
    },
    methods: {
        drawPie() {
            let mChart = this.$echarts.init(document.getElementById(this.id))

            let option = {
                title: {
                    text: this.chartTitle,
                    textStyle: {
                        color: '#333',
                        fontSize: 18
                    },
                    left: 'center', // 水平居中
                    top: '5%'      // 顶部距离为5%
                },
                series: [
                    {
                        type: 'pie',
                        data: this.ringData,
                        radius: ['28%', '45%'],
                        itemStyle: {
                            normal: {
                                color: function (params) {
                                    var colorList = [
                                        '#68bbc4', '#5087ec'
                                    ];
                                    return colorList[params.dataIndex];
                                }
                            }
                        },
                        label: {
                            position: 'outside',
                            formatter: (params) => {
                                return `${params.name}\n${params.value}${this.unit}`
                            }
                        }
                    }
                ]
            }

            mChart.setOption(option)
        }
    },
    created() {
        this.$nextTick(() => {
            this.drawPie()
        })
    }
}
</script>

<style></style>