<template>
    <div :id="id" :style="{ width: `${width}px`, height: `${height}px` }"></div>
</template>

<script>
export default {
    data() {
        return {

        }
    },
    props: {
        id: {
            type: String,
            require: true
        },
        categoryData: {
            type: Array,
            default: []
        },
        LineDataOne: {
            type: Array,
            default: []
        },
        LineDataTwo: {
            type: Array,
            default: []
        },
        width: {
            type: Number,
            default: 300
        },
        height: {
            type: Number,
            default: 300
        },
        chartTitle: {
            type: String,
            default: ''
        }
    },
    methods: {
        drawPie() {
            let mChart = this.$echarts.init(document.getElementById(this.id))

            let option = {
                xAxis: [
                    {
                        type: 'category',
                        data: this.categoryData,
                    }
                ],
                yAxis: [
                    {
                        type: 'value'
                    }
                ],
                series: [
                    {
                        name: 'Forest',
                        type: 'line',
                        barGap: 0,
                        data: this.LineDataOne,
                        itemStyle: {
                            color: '#68bbc4'
                        }
                    },
                    {
                        name: 'Forest2',
                        type: 'line',
                        barGap: 0,
                        data: this.LineDataTwo,
                        itemStyle: {
                            color: '#5087ec'
                        }
                    }
                ],
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                title: {
                    text: this.chartTitle,
                    textStyle: {
                        color: '#333',
                        fontSize: 18
                    },
                    left: 'center', // 水平居中
                    top: '5%'      // 顶部距离为5%
                }
            }
            console.log(option)
            mChart.setOption(option)
        }
    },
    created() {
        this.$nextTick(() => {
            this.drawPie()
        })
    }
}
</script>

<style></style>