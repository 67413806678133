<template>
    <div :id="id" :style="{ width: `${width}px`, height: `${height}px` }"></div>
</template>

<script>
export default {
    data() {
        return {

        }
    },
    props: {
        id: {
            type: String,
            require: true
        },
        categoryData: {
            type: Array,
            default: []
        },
        BarDataOne: {
            type: Array,
            default: []
        },
        BarDataTwo: {
            type: Array,
            default: []
        },
        width: {
            type: Number,
            default: 300
        },
        height: {
            type: Number,
            default: 300
        },
        chartTitle: {
            type: String,
            default: ''
        }
    },
    methods: {
        drawPie() {
            let mChart = this.$echarts.init(document.getElementById(this.id))

            let option = {
                xAxis: [
                    {
                        type: 'category',
                        data: this.categoryData,
                        axisLabel: {
                            rotate: 90, // 标签旋转角度
                            interval: 0, // 强制显示所有标签
                            // formatter: function (value) {
                            //     var str = "";
                            //     var maxLength = 3; // 每行最多显示3个字符
                            //     for (var i = 0; i < value.length; i += maxLength) {
                            //         str += value.slice(i, i + maxLength) + "\n";
                            //     }
                            //     return str;
                            // }
                        }
                    }
                ],
                yAxis: [
                    {
                        type: 'value'
                    }
                ],
                series: [
                    {
                        name: 'Forest',
                        type: 'bar',
                        barGap: 0,
                        data: this.BarDataOne,
                        itemStyle: {
                            color: '#68bbc4'
                        }
                    },
                    {
                        name: 'Steppe',
                        type: 'bar',
                        data: this.BarDataTwo,
                        itemStyle: {
                            color: '#5087ec'
                        }
                    }
                ],
                grid: {
                    top: '40px', // 将 grid 区域向上移动 20px
                    left: '0',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                title: {
                    text: this.chartTitle,
                    textStyle: {
                        color: '#333',
                        fontSize: 18
                    },
                    left: 'center', // 水平居中
                    top: '5%'      // 顶部距离为5%
                }
            }

            mChart.setOption(option)
        }
    },
    created() {
        this.$nextTick(() => {
            this.drawPie()
        })
    }
}
</script>

<style></style>