<template>
    <div class="content">
        <div class="top">
            <div class="left">
                <h1>累积在线设备：410</h1>
                <h1>累积报警次数：<span style="color:red">0</span></h1>
                <h1>累积误报警次数：<span style="color:red">1</span></h1>
                <h1>累积上门核实次数：1</h1>
                <h1>高频误报警原因：</h1>
                <div>
                    <span>血压异常</span>
                    <span>心率异常</span>
                </div>
                <h1>高频异常用户（点击查询具体情况）</h1>
                <div>
                    <span v-for="(item, index) in highFrequencyAbnormalList" :key="index"
                          @click="toUserDataCenter(highFrequencyUserIdList[index],'UserClass')">{{ item }}</span>
                </div>
            </div>
            <div class="right">
                <PieChart :id="'pieChartOne'" :pieData="onLineDeviceVal" :unit="'台'" chartTitle="绑定设备在线率" />
                <PieChart :id="'pieChartTwo'" :pieData="ErrorVal" :unit="'次'" chartTitle="误报警率" />
            </div>
        </div>

        <div class="bottom">
            <BarChart ref="barRegion" :id="'region'" :categoryData="UserCategoryData" :BarDataOne="UserBarDataOne"
                :BarDataTwo="UserBarDataTwo" :width="800" chartTitle="用户地域分布" />
            <PieChart ref="pieAge" :id="'age'" :pieData="ageData" :unit="'个'" chartTitle="用户年龄分布" />
            <PieChart ref="pieGender" :id="'gender'" :pieData="genderData" :unit="'个'" chartTitle="用户性别分布" />
        </div>

        <div class="bottom">
            <LineChart ref="lineRegion" :id="'wwjAnly'" :categoryData="LineCategoryData" :LineDataOne="LineDataOne"
                :LineDataTwo="LineDataTwo" chartTitle="误报警原因分析" />
            <RingChart :id="'qq'" :ringData="ErrorVal2" :unit="'次'" chartTitle="异常用户上门核实率" />
        </div>

    </div>
</template>

<script>
import PieChart from "@/components/PieChart.vue"
import BarChart from "@/components/BarChart.vue"
import LineChart from "@/components/LineChart.vue"
import RingChart from "@/components/RingChart.vue"
import VueMixin from "@/utils/VueMixin";
export default {
    components: {
        PieChart,
        BarChart,
        LineChart,
        RingChart
    },
    mixins:[VueMixin],
    data() {
        return {
            onLineDeviceVal: [
                {
                    name: "在线设备",
                    value: 80
                },
                {
                    name: "离线设备",
                    value: 20
                }
            ],
            ErrorVal: [
                {
                    name: "正确报警",
                    value: 80
                },
                {
                    name: "误报警",
                    value: 20
                }
            ],
            ErrorVal2: [
                {
                    name: "已上门核实",
                    value: 80
                },
                {
                    name: "未上门核实",
                    value: 20
                }
            ],
            ageData: [],
            genderData: [],
            UserCategoryData: [],
            UserBarDataOne: [],
            UserBarDataTwo: [],
            highFrequencyAbnormalList: [],
            LineCategoryData: ["1月", "2月", "3月", "4月", "5月"],
            LineDataOne: [10, 20, 50, 30, 60],
            LineDataTwo: [5, 26, 57, 21, 88],
            highFrequencyUserIdList: []
        }
    },
    methods: {
        async getDistributions() {
            let { data: res } = await this.$http.get(this.$api.getDistributions)
            console.log(res, "分布")
            if (res.code === 0) {
                let ageJh = res.data.age_distribution
                let genderJh = res.data.gender_distribution
                let regionJh = res.data.region_distribution
                //处理年龄数据
                for (let key in ageJh) {
                    this.ageData.push({
                        name: (key === "unknown") ? "未知" : key,
                        value: ageJh[key]
                    })
                }
                this.$refs["pieAge"].drawPie()
                //处理性别数据
                for (let key in genderJh) {
                    this.genderData.push({
                        name: (key === "unknown") ? "未知" : key,
                        value: genderJh[key]
                    })
                }
                this.$refs["pieGender"].drawPie()
                //处理地区数据
                for (let key in regionJh) {
                    this.UserCategoryData.push((key === "unknown") ? "未知" : key)
                    this.UserBarDataOne.push(regionJh[key])
                }
                this.$refs["barRegion"].drawPie()
            }
        },
    },
    created() {
        this.getDistributions()
        this.getHighFrequencyAbnormalUsers()
    }
}
</script>

<style lang="less" scoped>
.content {
    width: 100%;
    display: inline-flex;
    flex-direction: column;

    .top {
        width: 100%;
        display: inline-flex;
        flex-direction: row;

        .left {
            padding-left: 100px;

            h1 {
                font-size: 25px;
                margin: 10px 0;
            }

            h1:nth-child(6) {
                margin: 30px 0 20px;
            }

            div {
                display: inline-block;
                max-width: 400px;

                span {
                    display: inline-block;
                    width: auto;
                    cursor: pointer;
                    padding: 5px 10px 5px 0;
                }
            }
        }

        .right {
            margin-left: 200px;
        }
    }

    .bottom {
        width: 100%;
        display: inline-flex;
        flex-direction: row;
        padding-left: 100px;
        box-sizing: border-box;
    }
}
</style>